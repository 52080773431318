import { createRouter, createWebHistory } from 'vue-router'
import  store  from '../store';



 


const router = createRouter({
  history: createWebHistory(),
  routes:  [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/reg',
      name: 'Reg',
      component: () => import('../views/Reg.vue')
    },
    {
      path: '/selectwin',
      name: 'SelectWin',
      component: () => import('../views/SelectWin.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/selectphotos',
      name: 'SelectPhotos',
      component: () => import('../views/SelectPhotos.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/profileuser',
      name: 'ProfileUser',
      component: () => import('../views/ProfileUser.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/uploadimg',
      name: 'Uploadimg',
      component: () => import('../views/UploadimgProfile.vue')
    },
    {
      path: '/decode',
      name: 'decode',
      component: () => import('../views/DecodeQr.vue')
    },
    {
      path: '/recize',
      name: 'Rezice',
      component: () => import('../views/Recize.vue')
    },
    {
      path: '/sala/:id',
      name: 'Sala',
      component: () => import('../views/Retos.vue'),
      meta: {rutaProtegida: true}
    },

    {
      path: '/reto',
      name: 'Reto',
      component: () => import('../views/Reto.vue'),
      meta: {rutaProtegida: true}
    },

    {
      path: '/adminsala',
      name: 'Adminsala',
      component: () => import('../views/Sala.vue'),
      meta: {rutaProtegida: true}
    },
    
    {
      path: '/waitingplayer',
      name: 'WaitingPlayer',
      component: () => import('../views/WaitingPlayer.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/startreto',
      name: '/startreto',
      component: () => import('../views/StartReto.vue'),
      meta: {rutaProtegida: true}
    },
    {
      path: '/swip',
      name: '/swip',
      component: () => import('../views/Swip.vue')
    },
    {
      path: '/profilephoto',
      name: '/profilephoto',
      component: () => import('../views/Profilephoto.vue')
    },
    {
      path: '/misretos',
      name: '/misretos',
      component: () => import('../views/MisRetos.vue')
    },
    {
      path: '/optionscamera',
      name: '/optionscamera',
      component: () => import('../views/OptionsCamera.vue')
    },
    {
      path: '/cameraon',
      name: '/cameraon',
      component: () => import('../views/CameraOn.vue')
    },
    {
      path: '/invited',
      name: '/invited',
      component: () => import('../views/Invited.vue')
    },
    {
      path: '/monto',
      name: 'monto',
      component: () => import('../views/Monto.vue')
    },
    {
      path: '/cash',
      name: 'cash',
      component: () => import('../views/MontoToCash.vue')
    },

    {
      path: '/adddta',
      name: 'adddta',
      component: () => import('../views/PersonalDta.vue')
    },

    {
      path: '/codeGen',
      name: 'codeGen',
      component: () => import('../views/CodeGenerate.vue')
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: () => import('../views/Profile.vue')
    },

    {
      path: '/monkeyfall',
      name: 'monkeyfall',
      component: () => import('../views/Monkeyfall.vue')
    },

    {
      path: '/selectmonkey',
      name: 'selectmonkey',
      component: () => import('../views/SelectMonkey.vue')
    },

    {
      path: '/penalty',
      name: 'penalty',
      component: () => import('../views/Penalty.vue')
    },

    {
      path: '/penaltytiro',
      name: 'penaltytiro',
      component: () => import('../views/PenaltyTiro.vue')
    },

    

    {
      path: '/eggs',
      name: 'eggs',
      component: () => import('../views/Eggs.vue')
    },

    {
      path: '/selecteggs',
      name: 'selecteggs',
      component: () => import('../views/SelectEggs.vue')
    },

    

    {
      path: '/getOnGoogleplay',
      name: 'getOnGoogleplay',
      component: () => import('../views/GetOnGoogleplay.vue')
    },

    {
      path: '/rewards',
      name: 'rewards',
      component: () => import('../views/Rewards.vue')
    },
    {
      path: '/rewardshome',
      name: 'rewardshome',
      component: () => import('../views/RewardsHome.vue')
    },


    {
      path: '/:pathMatch(.*)*',
      name: 'nofound',
      component: () => import('../views/HomeView.vue')
    },
    
   
    
  ]
  
})

router.beforeEach(((to,from,next) =>{
  if (to.meta.rutaProtegida) {
    if (store.getters.autUser) {

      next()
    }else{
      next('/reg')
    }
  }else{
    next()
  }

}))

export default router
