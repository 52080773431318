import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {mapActions} from "vuex";
import {initializeApp} from "firebase/app";
import { doc, onSnapshot, setDoc,updateDoc, deleteField, getDoc } from "firebase/firestore";
import { getFirestore, serverTimestamp} from "firebase/firestore";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import firebase from "firebase/app";
import { getFunctions } from 'firebase/functions';
import 'firebase/functions'




const firebaseConfig = {
    apiKey: "AIzaSyC6_bdIGk8MIAYaOIyUKhq_O246ieeSrgo",
    authDomain: "moneygo-b335c.firebaseapp.com",
    databaseURL: "https://moneygo-b335c-default-rtdb.firebaseio.com",
    projectId: "moneygo-b335c",
    storageBucket: "moneygo-b335c.appspot.com",
    messagingSenderId: "425049311811",
    appId: "1:425049311811:web:6401c9da55b07151d162f6"
  };

  const app = initializeApp(firebaseConfig);
 

createApp(App).use(store).use(router).mount('#app')





  const uid = store.state.user.uid
  const db = getFirestore()
 


















 






if(uid){
  const unsub = onSnapshot(doc(db,'tokens',uid),(doc) => {
    const data = doc.data().token
    const token = localStorage.getItem('IDdevice')
  
    if(data){
      if (data.length == undefined) {
        // this.waitAnswer() 
        
     }else{
      if (data != token) {
            console.log('alguien mas inicio sesion');  
      }else{
        console.log('todo esta perfecto');
      }
         
     }
    }
       
        
    });
}
  






 
   





function invited() {
  //notificar quien me ha invitado
const db = getFirestore()
const uid =  store.state.user.uid

if(localStorage.getItem('dataSesion')){
  const unsub = onSnapshot(doc(db,'Inviteds',uid),(doc) => {
    console.log('hubo cambios');
    
    const currentToken = store.state.user.notificationToken
    store.commit('sendToken',currentToken)
    
    const data = doc.data()
        if (data) {
          for(let i in data){
           
            if (!data[i].active && !data[i].acepted && data[i].uid != uid) {
              
              if(data[i].otherFriends.length>1){
                
              }else{
                
              }
              
            }
            
          }
          
        }
        
    });
}
}


//invited()


let idReto = ''
let datos = ''

//notificacion local
/* if (uid) {
  const unsub = onSnapshot(doc(db,'localNoti',uid),(doc) => {
  

  
    for(let i in doc.data()){

      idReto = i
      datos = doc.data()[i].status;
      if (datos) {
        deleteDataLocalNoti()
      }

      notisend(i,doc.data()[i].status,doc.data()[i].other)
    } 
        
  });

}
    

  
function deleteDataLocalNoti(){

    setTimeout(() => {
    
      updateDoc(doc(db, "localNoti", uid),{
        [idReto]: deleteField()
      });
    store.state.contentLocalNoti = []
    }, 6000);

}

async function notisend(i,status,otherUserId){
  try {

                const uid =  store.state.user.uid
                const token =  store.state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/inviteds/${otherUserId}/user/${uid}/${i}.json?auth=${token}`)
                const dataDb = await res.json()
                if (dataDb) {
              
            
                    store.commit('localNoti',{
                      idReto: i,
                      result: status,
                      valor: dataDb.valor,
                      player: dataDb.player     
                    });
                  
                }else{
                  const resdata = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/retos/${uid}/${i}.json?auth=${token}`)
                  const dataDbdata = await resdata.json()
                  if (dataDbdata) {
                    
                    
                   
                      store.commit('localNoti',{
                        idReto: i,
                        result: status,
                        valor: dataDbdata.valor,
                        player: dataDbdata.player     
                      });
                    
                  }
                }
                

   

  } catch (error) {
    
  }
}
  
     */

  



  





function miles(valor) {
  let numero = valor
  let partesNumero = numero.toString().split('.');
  partesNumero[0] = partesNumero[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return partesNumero.join('.');    


}

//notificar quien acepto mi invitación


 

const interval = setInterval(() => {
  if (Notification.permission === 'granted'){
    if(localStorage.getItem('dataSesion')){
      //invited()
      
      clearInterval(interval)
    }
    
  }
}, 3000);

 



async function changeStatus(id){

  const datos = JSON.parse(localStorage.getItem("misRetos"))
  
      const misRetos = []
      if (datos) {
            for(let i in datos){
       
              
              if (datos[i].id === id) {
                datos[i].status = true
              }
              misRetos.push(datos[i])
            }  
          
            localStorage.setItem('misRetos',JSON.stringify(misRetos))
              try {
                const uid =  store.state.user.uid
                const token =  store.state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                       method: 'PUT',
                       body: JSON.stringify(misRetos)   
                })
                const dataDb = await res.json()
              
              } catch (error) {
                //console.log(error);
              }
          
 
      }else{
        
      }
}






//send hours



///notificar quien acepto mi reto


//verificar funcionalidades de contacto en el telegono primeor en el computador 

// Hace una solicitud de almacenamiento persistente al sitio



