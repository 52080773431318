
import router from '@/router';
import { createStore } from 'vuex'
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc,deleteDoc, onSnapshot, setDoc, updateDoc,Timestamp,deleteField} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import moment from "moment";


 

export default createStore({
  state: {
    rewardData: '',
    whereGo: '',
    salaActive: '',
    user:{  
      id:"",
      uid: "",
      token: "",
      notificationToken: '',
      tokui: '',
      nameUser: '',
          img: "",
          cel: '',
      cuentaB: {
          bankName: '',
          accountName: '',
          accountType: '',
          accountNumber: '',
          disponible: 0,
          asigned: 0,
          servicio: 0,
          saldo: 0  //esta saldo es solo para pintarce pero en cada peticion sera actualizado desde la base de datos
        },
        
      categoryImg: "",
      groupImg: "",
      imgsReto: [],
      retodadata: [],
      idReto: '',
      idMyreto: '',
      modeAceptInvited: '',
      modeAceptInvitedUserId: '',
      modeAceptIdReto: '',
      option: 0 ,
      iduser: '',
      imguser: '' 
    
    },

    valorshareReto: '',
    retoOtherUserToMe: [],
    acepteds: [],
    intertime: 0,
    modeInvited: false, //define if user select your photo from your gallery
    invitedFriends: [],
    invitedFriendsCount: [],
    misRetos: [],
    misResInviteds: [],
    phototake: [],
    contentLocalNoti: [],
    changeImg: false,
    changingImg: false,
    changeData: '',
    front: false, //define if the photos has been taked with front camera
    offline: false,
    retosActivos: 0,
    invitaciones: 0,
    countInviteds: 0,
    mensaje: '',
    hour: '',
    ativeCound: 0,
    categoryQuestions: ''
   
  },
  getters:{

  },
  mutations: {
    

    async sendToken(state,payload) {
      const uid =  state.user.uid
      const db = getFirestore()
      try {
      state.user.notificationToken = payload
      if (uid) {
        await setDoc(doc(db, "tokens", uid),{
          token: payload
         });
        
        localStorage.setItem('IDdevice', payload)
      }
       
    
        
      } catch (error) {
        //console.log(error);
      }
    },


   async newtoken(state){
    const usuario ={
      nombre : '',
      email : '',
      uid: '',
      token: '',
      foto: '',
    } 
    
    if (localStorage.getItem('dataSesion')) {
      const dataUser = localStorage.getItem('dataSesion')
      const datos = JSON.parse(dataUser)
      usuario.uid = datos.uid
      usuario.email = datos.email
      usuario.nombre = datos.nombre.split(' ')[0]
      usuario.foto = datos.foto
    }
    try {     
      const res = await getAuth().currentUser.getIdToken(true)
      const dataDb = await res
      usuario.token = dataDb
      state.user.token = dataDb
      localStorage.setItem('dataSesion',JSON.stringify(usuario))
      } catch (error) {
       //console.log(error);
    }

    },

   async addmisRetos(state,payload){ 
      const datos = JSON.parse(localStorage.getItem("misRetos"))
      state.misRetos = []
      const db = getFirestore()
      if (datos) {
            for(let i in datos){
              state.misRetos.push(datos[i])
            }  
          if (!state.misRetos.includes(payload) ) {
              state.misRetos.push(payload)
              //console.log(state.misRetos);
              localStorage.setItem('misRetos',JSON.stringify(state.misRetos))
              try {
                
                const uid =  state.user.uid
                console.log(state.misRetos);
                
                await setDoc(doc(db, "mylocalstorage", uid),{
                    ...state.misRetos
                    
                  },{merge:true},console.log('datos enviados a mylocalStorage'));
              } catch (error) {
                //console.log(error);
              }
          }
 
      }else{
        console.log('no datos');
        
        state.misRetos.push(payload);
        localStorage.setItem('misRetos',JSON.stringify(state.misRetos))
        const db = getFirestore()
        try {
          const uid =  state.user.uid
          console.log(state.misRetos);
                await setDoc(doc(db, "mylocalstorage", uid),{
                  ...state.misRetos
                    
                  },{merge:true},console.log('se envio correctamente'));
        } catch (error) {
          console.log(error);
        }
        
      }
      
     
    },

    
    async addmisRetosInviteds(state, payload) {
      const db = getFirestore();
      console.log(payload);
      
      
      // Verificar si el payload es válido
      let existingIds = new Set(); // Definir la variable existingIds antes del bloque if
      
      if (payload !== 'Permission denied') {
        // Crear un conjunto de IDs existentes para evitar duplicados
        existingIds = new Set(state.misResInviteds.map(invited => invited.id));
      
        // Agregar el nuevo payload solo si no existe
        if (!existingIds.has(payload.id)) {
          state.misResInviteds.push(payload);
        }
      
        // Guardar en localStorage
        localStorage.setItem('misRetosInviteds', JSON.stringify(state.misResInviteds));
      } else {
        // Si el payload es 'Permission denied', simplemente agregarlo
        state.misResInviteds.push(payload);
        localStorage.setItem('misRetosInviteds', JSON.stringify(state.misResInviteds));
      }
      
      try {
        const uid = state.user.uid;
      
        // Obtener los datos existentes de Firestore
        const docRef = doc(db, "mylocalstorage", uid);
        const docSnap = await getDoc(docRef);
      
        let existingInviteds = [];
        if (docSnap.exists()) {
          existingInviteds = docSnap.data().Inviteds || [];
        }
      
        // Combinar los datos existentes con los nuevos
        const combinedInviteds = [...existingInviteds, ...state.misResInviteds.filter(invited => !existingIds.has(invited.id))];
      
        // Guardar los datos combinados en Firestore
        await setDoc(docRef, { Inviteds: combinedInviteds }, { merge: true });
      } catch (error) {
        console.error('Error al guardar en Firestore:', error);
      }
    },

   
    retoOtherUserToMe(state,payload){
      state.retoOtherUserToMe.push(payload)
      //console.log(state.retoOtherUserToMe);
    }, 


    async eliminar(state,payload){
     
      try {
        const idReto = state.user.idReto
        const token = state.user.token
       
        const db = getFirestore()
        let retos = []
        const datos = JSON.parse(localStorage.getItem("misRetos"))
       
        const ref = doc(db, "retos", payload)
        await deleteDoc(ref)
       
        const docSnap =  await getDoc(ref)
        if (docSnap.exists()) {
          alert('No se puedo eliminar este reto intentalo de nuevo')
        }else{
          const countlocal = []
              const local = JSON.parse(localStorage.getItem('localAcepteds'))
              if (local) {
                    if(local.length >1){
                      for(let i in local){ 
                        if (local[i] != payload) {
                          countlocal.push(local[i])
                        }    
                      }
                      localStorage.setItem('localAcepteds',JSON.stringify(countlocal))  
                    }else{
                      localStorage.setItem('localAcepteds',JSON.stringify(countlocal))  
                    }

              }else{
                
              }

         //functions borrar retencion
         const uid = state.user.uid
          await setDoc(doc(db, "retencion", payload),{
                del: true,
                iduser: uid
          },{merge:true})


          for(let i in datos){
              
            if (datos[i].id == payload) {
               if (datos.length == 1) {
                retos = []
                localStorage.removeItem('misRetos')
                const uid = state.user.uid
               
    
                await setDoc(doc(db, "mylocalstorage", uid),{
                  ...retos
                    
                  },{merge: true});
               }
            }else{
              retos.push(datos[i]) 
              localStorage.setItem('misRetos',JSON.stringify(retos))
             
            }
            
          }

            

          // Consultamos los datos
          const myLocalStorageRef = doc(db, "mylocalstorage", uid);
          const myLocalStorageSnap = await getDoc(myLocalStorageRef);

          if (myLocalStorageSnap.exists()) {
              const res = myLocalStorageSnap.data();
              let indexToDelete = '';
              console.log(res);
              
              // Encontrar el índice que corresponde al payload
              for (let i in res) {
                  if (res[i].id == payload) {
                      indexToDelete = i;
                      break; // Salir del bucle una vez encontrado
                  }
              }

              // Si encontramos el índice, proceder a eliminarlo
              if (indexToDelete) {
                  // Usar updateDoc para eliminar el campo
                  await updateDoc(myLocalStorageRef, {
                      [indexToDelete]: deleteField() // Eliminar el campo correspondiente
                  });
                  console.log(`El campo ${indexToDelete} ha sido eliminado.`);

                  // Obtener los datos actuales de misRetos en localStorage
                  const misRetos = JSON.parse(localStorage.getItem('misRetos')) || [];

                  // Filtrar los datos para eliminar el que coincide con el payload
                  const updatedRetos = misRetos.filter(reto => reto.id !== payload);

                  // Actualizar localStorage
                  if (updatedRetos.length > 0) {
                      localStorage.setItem('misRetos', JSON.stringify(updatedRetos));
                  } else {
                      localStorage.removeItem('misRetos'); // Eliminar misRetos si no hay datos
                  }
              } else {
                  console.log('No se encontró el elemento con el id proporcionado.');
              }
          } else {
              console.log('El documento no existe.');
          }



            ////esto esta en veremos no se si es necesario
           /*  await setDoc(doc(db, "mylocalstorage", uid),{
              misRetos: retos.length>0?retos:''
                
              },{merge: true}); */
                    
        
        }//finaliza else
          

            
          

          
        

       
        
          

          //splice no funciona
        

        

        
        
       //ELIMINAR DEL LOCAL STORAGE

       

        
      } catch (error) {
        //console.log(error);
      }
    },

    changerutegroupImg(state, payload){
      state.categoryImg = payload;
      
      
    },

    temporalImg(state,payload){
      state.user.imgsReto = []
    
      
      state.user.imgsReto.push({url1: payload[0], url2: payload[1]})
      console.log(state.user.imgsReto);
      
    },
    
   async imgselect(state, payload){//agrega el grupo de imagen que elegi a un array  funcion de Retos
      state.user.imgsReto = []
      state.user.imgsReto.push({url1: payload.imagenes.url1, url2: payload.imagenes.url2, })
      if (payload.idReto) {
        state.user.idReto = payload.idReto; 
      }
      
  
     //enviar datos al cache

      // const cadena1 = payload.imagenes.url1.indexOf('?alt=')
      // const caden1a = payload.imagenes.url1.slice(0,cadena1)
      // const cadena2 = payload.imagenes.url2.indexOf('?alt=')
      // const caden2a = payload.imagenes.url2.slice(0,cadena2)
      // const token1 = payload.imagenes.url1.slice(cadena1)
      // const token2 = payload.imagenes.url2.slice(cadena2)

      // state.user.imgsReto.push({token1: token1, token2: token2})
      // //console.log(state.user.imgsReto.token1);
      // //console.log(state.user.imgsReto.token2);
      // caches.open('invited').then( cache =>{
      //   cache.addAll([
      //     caden1a,
      //     caden2a
      //   ])
      // })


     
      
    },

    saveIdReto(state, payload){
      console.log(payload);
      
      state.user.retodadata = []
      state.user.idMyreto = payload
      state.user.idReto = payload
      console.log('datos desde Index:'+ payload);
      
      state.user.retodadata.push(payload)
      ;
      
      
      
      
      
      
    },


    routeimg(state, payload){
      state.routeImg = payload
    },

    getUser(state, payload){  //aqui recogemos los primeros datos 
      state.user.nameUser = payload.nameUser,
      state.user.cel = payload.telephone  
    },

    async addPlayerToReto(state,payload){//agregar datos a player cuando usuario acepta el reto
      const token = state.user.token
      const db = getFirestore()
      try {

       
          //console.log(payload.dataReto);
          if(payload.dataReto){
        
            await setDoc(doc(db, "retos", payload.dataReto.idreto),{
              player:{
                  fotoplayer: state.user.img,
                nameplayer: state.user.nameUser,
              }
                
              },{merge: true});
         
          }else{
           

            await setDoc(doc(db, "retos", payload),{
              player:{
                  fotoplayer: state.user.img,
                nameplayer: state.user.nameUser,
              }
                
              },{merge: true});
            
          //console.log('datos agregados');
          
          }
        
        
      } catch (error) {
        //console.log(error);
      }
    },

    async addUser(state){ //aqui registramos el usuario en la base de datos despues de la confirmacion del telfono
      try {
        const id = state.user.cel
        const res =  await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/users/${id}.json`,{
              method: 'PUT',
              body: JSON.stringify(state.user)
          })

      const datadb = await res.json();
      //console.log(datadb);
      } catch (error) {
          //console.log(error);
      }
    },

   async loadData(state){
   
    try {
                
      const uid = state.user.uid
      const db = getFirestore()
      const docRef = doc(db, "usuarios", uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const url = docSnap.data().foto
        const aviso =  docSnap.data().aviso
        if(aviso){
          localStorage.setItem('aviso',aviso)
        }else{
          localStorage.removeItem('aviso')
          console.log('aun no has aceptado las codicioens de la heramienta generar');
          
        }
        const usuario ={
          nombre : '',
          email : '',
          uid: '',
          token: '',
          foto: url, 
      } 
        const dataUser = localStorage.getItem('dataSesion')
        const datos = JSON.parse(dataUser)
        usuario.uid = datos.uid
        usuario.token = datos.token
        usuario.email = datos.email
        usuario.nombre = docSnap.data().nombre.split(' ')[0]
        console.log(usuario.nombre);
        localStorage.setItem('dataSesion',JSON.stringify(usuario))
        state.user.img = url   
        state.changingImg = false
        state.user.nameUser = docSnap.data().nombre.split(' ')[0]
      } else {
        // doc.data() sera undefined en este caso
        //console.log("no se encuentra el documento");
      }
    } catch (error) {
        //console.log(error);
    }
    },



    bank(state){
      const db = getFirestore()
      const uid = state.user.uid
      if (uid) {
        const unsub = onSnapshot(doc(db,'saldo',uid),(doc) => { //snapshot informacion bancaria
          const data = doc.data()
            if (data) {
              if (data.account) {
                state.user.cuentaB.saldo = data.account.saldo
                state.user.cuentaB.disponible = data.account.disponible
                state.user.cuentaB.asigned = data.account.asigned || 0
                state.user.cuentaB.servicio = data.account.servicio
                localStorage.setItem('bankInfo',JSON.stringify(data.account))
              }
            }else{
                const miSaldo = JSON.parse(localStorage.getItem('bankInfo'))
                state.user.cuentaB.saldo = miSaldo? miSaldo.saldo : 0
            }
              
               
              
          });
      }
      
    },

    async loadPersonalData(state){
      try {
          const uid =  state.user.uid
          const db = getFirestore()
          const docRef = doc(db, "usuarios",uid);
          const docSnap = await getDoc(docRef);
          state.user.id = docSnap.data().idUsuario
          localStorage.setItem('idUsuario',docSnap.data().idUsuario)
          if (docSnap.data().account.personalData.Documentnumber) {
            
            const data = []
            data.push(docSnap.data().account.personalData)
            data.push({idUsuario:docSnap.data().account.idUsuario})
            
              localStorage.setItem('personlaData',JSON.stringify( data ))
              
          }
      } catch (error) {
          //console.log(error);
      }
      
  },


    //cargar datos de usuario del localstorage
    async loadDataUser(state){
     
      if (localStorage.getItem('dataSesion')) {
      const dataUser = localStorage.getItem('dataSesion')
      const datos = JSON.parse(dataUser)
      state.user.uid = datos.uid
      state.user.token = datos.token
      state.user.nameUser = datos.nombre?datos.nombre.split(' ')[0]:'Usuario'
      state.user.img = datos.foto != null?datos.foto:'https://firebasestorage.googleapis.com/v0/b/moneygo-b335c.appspot.com/o/profile%2Fuser.png?alt=media&token=a2dc3c31-6df5-46e0-9b42-4f3c02c30805'
      }
      if (localStorage.getItem('bankInfo')) {
        const miSaldo = JSON.parse(localStorage.getItem('bankInfo'))
        for(let i in miSaldo){
          state.user.cuentaB.saldo = miSaldo.saldo
        }
      }

      //cargar datos de banco
      try {
      
        

              
              if (localStorage.getItem('dataSesion')) {

                const local = []
                const retosInvited = []
                const uid = state.user.uid
  
                const db = getFirestore()
                const Ref = doc(db, "mylocalstorage", uid);
                const docSnapp = await getDoc(Ref);

                           
                if (docSnapp.exists()) {
                  
                  for(let i in docSnapp.data()){
                    
                    if(docSnapp.data()[i]!='' && i != 'users' && i != 'Inviteds'){
                      
                       //console.log(docSnapp.data()[i]);
                       
                      local.push(docSnapp.data()[i])
                    }

                    //si hay retos invited entrara aqui
                    if(i == 'Inviteds'){
                      retosInvited.push(...docSnapp.data()[i])
                      
                    }
                   
                  }

                  if(retosInvited.length>0 && retosInvited != ''){
                    localStorage.setItem('misRetosInviteds', JSON.stringify(retosInvited));
                  }else{
                    localStorage.removeItem('misRetosInviteds')
                  }
                 
                  
                  if (local.length>0 && local != '') {
                    
                    
                    localStorage.setItem('misRetos',JSON.stringify(local))
                  }else{
                  
                    
                    localStorage.removeItem('misRetos')
                    
                  }
                    
                }
              }else{
                
              }
             

        
      } catch (error) {
        //console.log(error);
      }
      
      
    },

    
    localNoti(state,payload){
      state.contentLocalNoti = []
      state.contentLocalNoti.push(payload)
    },
    

    noti(state,payload){
      const options ={
      body: payload.body,
      icon: payload.icon?payload.icon:require('../assets/icon-72x72.png'),
      badge: payload.badge,
      tag: payload.tag,
      }
     
      
        if (Notification.permission == "granted"){
          navigator.serviceWorker.getRegistration()
          .then(reg =>{
          reg.showNotification(payload.title,options);
          //window.navigator.vibrate([200, 100, 200]);
          })
      }
      

     },

     async sendHistory(state,payload){ //enviar como historial cada accion que implique salida o entrada de dinero
      const db = getFirestore()
      const uid = state.user.uid
      const token = state.user.token 
      console.log(payload);
      
      try {
        if(uid){
          //console.log(payload.id);
          await setDoc(doc(db, "History", uid),{
            
            [payload.restored?payload.id+'res':payload.id]:{
                  createAt: payload.createAt?payload.createAt:'',
                  id: payload.id?payload.id:'',
                  myres: payload.myres?payload.myres:'',
                  status: payload.status?payload.status:'',
                  type:payload.type?payload.type:'',
                  valor: payload.valor?payload.valor:'',
                  other: payload.other?payload.other:'',
                  mode: payload.mode?payload.mode:'',
                  restored: payload.restored?payload.restored:'',
                  otherFriends: payload.otherFriends?payload.otherFriends:'',
                  userdeleted: payload.userdeleted?payload.userdeleted:'',
                  idretoUsers: payload.idretoUsers?payload.idretoUsers:'',
                  iduser: payload.iduser?payload.iduser:'',
            }
            
          },{merge:true},console.log('se enviaron datos a el historial')
          )
        }
        

      } catch (error) {
        //console.log(error);
      }
      
     },

     async getMyHistory(state){
      const uid = state.user.uid;
      const db = getFirestore()
      if (uid) {
        const docRef = doc(db, "History", uid);
      const docSnap = await getDoc(docRef);
      const datos = []
      for(let i in docSnap.data()){
          datos.push(docSnap.data()[i]);
      }

      localStorage.setItem('myHistory',JSON.stringify(datos))
      }
      
  },

    async  hour(state){
      const db = getFirestore()
      moment.locale('es');
      const createdAt = Timestamp.fromDate(new Date())
      const hour = moment(createdAt.seconds*1000).format('L')
   
      state.hour = hour
      if (hour) {
        localStorage.setItem('hour', JSON.stringify(hour))
        
      }else{
        state.hour = JSON.parse(localStorage.getItem("hour"))
      }
     

    },




  },
  actions: {
    temporalImg({commit}, payload){ //funcion de swiper
      commit('temporalImg', payload)   
    },
    selecPhoto({commit}, payload){
      commit('imgselect', payload)  
      
    },

    saveIdReto({commit}, payload){
      commit('saveIdReto', payload)
    },

     agregar({commit}, payload){
      commit('getUser', payload)
    },
    addUser({commit}){
      commit('addUser')
    },

    loadData({commit}){
      commit('loadData')
    },
    routeimg({commit}){
      commit('routeimg')
    },
    change({commit}, payload){
      commit('changerutegroupImg',payload)
    },
    loadDataUser({commit}){
      commit('loadDataUser')
    },
    addmisRetos({commit}, payload){
      commit('addmisRetos',payload)
    },
   
    eliminar({commit},payload){
      commit('eliminar',payload)
    },
    sendDataretoLocalstorage({commit},payload){
      commit('sendDataretoLocalstorage',payload)
    }
  },

  getters: {
    autUser(state){
      return !!state.user.nameUser
    }
  }

})


